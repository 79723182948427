body {
    background: #1d1d20 !important;
    color: #fff !important;
}

.container h1 {
    font-size: 60px;
    font-weight: lighter;
    line-height: 80px;
    padding-bottom: 5px;
}

.container h2 {
    font-size: 30px;
    line-height: 30px;
    font-weight: 300;
}

.project-wrapper:before {
    content: " ";
    width: 90px;
    height: 90px;
    background: linear-gradient(135deg, rgb(84, 124, 154), rgb(72, 2, 122), rgb(84, 124, 154)100%);
    opacity: 1;
    border-top-left-radius: 8px;
    transition: all .3s ease-in-out;
    position: absolute;
    top: -2px;
    bottom: 0;
    left: -2px;
}

.project-wrapper div {
    z-index: 1;
    background: #1d1d20;
    border-top-left-radius: 7px;
    flex-direction: column;
    margin-left: 0;
    padding-top: 30px;
    display: flex;
    position: relative;
}

.container {
    max-width: 1200px !important;
    height: 100% !important;
    border-right: 2px;
    border-left: 2px;
    border-style: solid;
    border-image: linear-gradient(to bottom, rgb(84, 124, 154), rgb(72, 2, 122), rgb(84, 124, 154)) 1 100%;
    padding: 30px !important;
    margin-top: 40px;
}

.inner-container {
    padding: 30px 10px;
}

.vertical-center {
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.gradient {
    max-width: 140px;
    border-top: 3px solid;
    border-image: linear-gradient(to right, rgb(84, 124, 154), rgb(72, 2, 122), rgb(84, 124, 154))1 0 0 0;
}

.details {
    margin-top: 30px;
    padding: 0 48px;
}

/* .project-wrapper {
    max-height: 600px;
    padding: 0 10px;
    border-width: 3px;
    border-style: solid;
    border-image: 
      linear-gradient(
        to bottom, 
        rgb(84, 124, 154), 
        rgba(0, 0, 0, 0)
      ) 1 100%;
} */
.project-wrapper {
    position: relative;
}

.project-wrapper:before {
    content: " ";
    width: 90px;
    height: 90px;
    background: linear-gradient(135deg, rgb(84, 124, 154), rgb(72, 2, 122), rgb(84, 124, 154)100%);
    opacity: 1;
    border-top-left-radius: 8px;
    transition: all .3s ease-in-out;
    position: absolute;
    top: -2px;
    bottom: 0;
    left: -2px;
}

.project-wrapper div {
    z-index: 0;
    background: #1d1d20;
    border-top-left-radius: 7px;
    flex-direction: column;
    margin-left: 0;
    padding-top: 30px;
    display: flex;
    position: relative;
}

/* .project-wrapper {
    max-height: 600px;
    padding: 0 10px;
    border-width: 3px;

} */

.project-wrapper #navbar-link a {
    color: #fff;
    margin-bottom: 20px;
}

.project-wrapper #navbar-link a:after {
    display: block;
    content: '';
    height: 2px;
    background: rgb(72, 2, 122);
    transform: scaleX(0.0001);
    transition: transform 250ms ease-in-out;
    box-shadow: inset -40px 0px 30px -25px #547c9a, inset 40px 0px 30px -25px #547c9a;
    margin-top: 5px;
    width: 60px;
}

.project-wrapper #navbar-link a:after {
    transform: scaleX(1);
}

.project-wrapper #navbar-link a:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}


@media (max-width: 575.98px) {
    .container {
        height: 100% !important;
    }

    .container h1 {
        font-size: 60px;
    }

    .intro-img,
    .pups {
        display: none;
    }

    .details {
        padding: 0;
    }

}

@media (max-width: 991.98px) {}

/* home page css */

/* about page */
#about-title {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 70px;
}

#about {
    color: rgb(49, 49, 49);
}

/* resume page */
.resume {
    height: 100%;
    width: 100%;
}

#resume-title {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 70px;
}

#resume-title-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
}

.pdf {
    color: white !important;
}

@media only screen and (max-width: 991px) {
    /* about page */


}

@media only screen and (max-width: 768px) {
    /* about page */

}

@media only screen and (max-width: 521px) {

    /* home page */
    .title {
        font-size: 60px;
    }

    /* about page */
    #about-title {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 40px;
    }

    /* .project-wrapper {
        margin-top: 20px;
    } */

    .mobile-view {
        margin-top: 10px;
    }

    /* resume page */
    #resume-title {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 40px;
    }

}