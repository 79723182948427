.vertical-center {
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.intro-img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: block;
    margin-left: 20px;
    border-radius: 50%;
}
.pups {
    max-width: 200px;
    max-height: 200px;
    border-radius: 4px;
}

.max {
    float: right;
}