#main-nav {
    padding: 0;
    margin-bottom: 20px;
}

#main-nav a {
    color: rgb(209, 209, 209);
    font-size: 16px;
    line-height: 18px;
}

.navBrand b {
    font-size: 20px;
}

.icon {
    position: relative;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.icon::before,
.icon::after {
    position: absolute;
    width: 15px;
    height: 15px;
    content: "";
}

.icon::before {
    left: 0;
    top: 0;
    border-left: 3px solid #547c9a;
    border-top: 3px solid #547c9a;
    border-radius: 10px;
}
.icon::after {
    right: 0;
    bottom: 0;
    border-right: 3px solid #547c9a;
    border-bottom: 3px solid #547c9a;
    border-radius: 10px;
}
.navbar-toggler {
    background: #547c9a !important;
}
.navbar-light .navbar-toggler-icon {
    border-radius: 4px;
    background: white;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);
}