.card {
    width: 100%;
    /* height: 246px; */
}

.card h6 {
    color: #ffffff;
}

.card p {
    color: #ffffff;
    font-size: 12px;
}

.card-body {
    height: 70px;
}

.eyebrow {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
}

.eyebrow2 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
}

.list-group a {
    color: black;
}

.list-group a:hover,
.list-group a:focus {
    text-decoration: underline;
}

.uopx .list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgb(219, 55, 37);
}

.bootcamp .list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #0c234b;
}

a.fa-github {
    text-decoration: none !important;
}

.thumb-imgs img {
    width: 100%;
    height: 200px;
    border-radius: 4px;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

.thumb-imgs img:hover {
    opacity: 0.5;
}

.laptop-view,
.mobile-view {
    width: 100%;
    height: 380px;
}

@media (max-width: 575.98px) {
    .thumb-imgs img {
        width: 100%;
        height: 300px;
        margin: 10px auto;
    }

    .mobile-view {
        width: 60% !important;
        height: 400px !important;
        margin: auto;
    }

}
@media (max-width: 1199.98px) { 
    .laptop-view,
    .mobile-view {
        width: 100%;
        height: 100%;
    }
}
