.image-gallery {
    display: flex;
    flex-wrap: wrap;
  }
  
  .gallery-image {
    cursor: pointer;
    margin: 5px;
  }
  
  .popup-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  
  .popup-content {
    max-width: 50%;
    max-height: 100%;
  }
  
  .popup-content img {
    background-color: black;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
  .popup-content img:hover {
    opacity: 1 !important;
  }

  .popup-content img:hover {
    background-color: black;
  }